import StyledPagination from "@/components/styledPagination";
import { useRouter } from "next/router";
import Layout from "@/components/Layout";
import dynamic from "next/dynamic";
import Link from "next/link";
import qs from "qs";
import { fetcher } from "@/utils/utils-cjs";
import CoinList from "@/components/CoinList";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
const CoinzillaHorizontal = dynamic(
  () => import("@/components/adBanners/CoinzillaHorizontal"),
  { ssr: false }
);

const prefix = `/?page=`;
import { buttonItemRender } from "@/utils/utils";

const StyledDataTableWrapper = styled.div`
  .rdt_TableRow {
    background: transparent;
  }
  .rdt_TableHeadRow {
    display: none;
  }

  .rdt_TableCell {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default function Home({
  data: {
    data,
    meta: { pagination },
  },
}) {
  const { t } = useTranslation("lists");
  const router = useRouter();
  const parsedData = data.map((item) => {
    return { ...item.attributes, id: item.id };
  });
  const columns = [
    {
      id: `name`,
      name: `Name`,
      cell: (row) => (
        <CoinList
          coin={row}
          title={t(`articleTitle`, { name: row.name, symbol: row.symbol })}
        />
      ),
    },
  ];
  const handlePageChange = async (page) => {
    await router.push(`${prefix}${page}`);
  };
  return (
    <Layout title={`Crypto Buying Tips`}>
      <div>
        <CoinzillaHorizontal id="top" />
        <StyledDataTableWrapper>
          <DataTable
            columns={columns}
            data={parsedData}
            noHeader={true}
            pointerOnHover
          />
        </StyledDataTableWrapper>

        {/*<StyledPagination*/}
        {/*  onChange={handlePageChange}*/}
        {/*  current={pagination.page}*/}
        {/*  total={pagination.total}*/}
        {/*  pageSize={pagination.pageSize}*/}
        {/*  locale={`en-US`}*/}
        {/*  itemRender={buttonItemRender(prefix)}*/}
        {/*/>*/}
      </div>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const qs = require("qs");
  const fetcher = require("@/utils/utils-cjs").fetcher;

  let { page } = context.query;
  const { locale } = context;
  if (page !== undefined && isNaN(page)) {
    return {
      notFound: true,
    };
  } else {
    const query = qs.stringify(
      {
        pagination: {
          // page: page === undefined ? page : Math.abs(Math.round(page)),
          page: 1,
          pageSize: 10,
        },
        sort: "cmc_rank:asc",
        fields: ["cmc_id", "url_slug", "cmc_rank", "name", "symbol"],
      },
      { sort: (a, b) => a.localeCompare(b) }
    );
    const res = await fetcher(`/api/coins?${query}`);
    const data = await res.json();

    if (data.data.length >= 1) {
      return {
        props: {
          data,
          ...(await serverSideTranslations(locale, ["lists"])),
        },
      };
    } else {
      return {
        notFound: true,
      };
    }
  }
}
